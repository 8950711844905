import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseDocumentsDTO } from '@Models/domain/dto';
import { CaseDocumentsDataService, CaseSummaryService, FeatureManagerService, NotificationService, NotificationSeverity, SignalRService } from '@Services';
import {
    CaseRequirement,
    CaseRequirementResponse,
    RequirementResponse,
    UWRequirementActionEvent,
    UWRequirementTableData,
} from '@Underwriting/models';
import UWBaseProps from '@Underwriting/models/uw-base-props';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { CaseRequirementStatuses } from '@Underwriting/types';
import { CaseDocumentStatus, FeatureToggle, IntegrationEnum } from '@Enums';
import { SubSink } from 'subsink';

import {
    AttachmentFileManagerDialog,
    AttachmentFileManagerDialogData,
    EvidenceManagerDialog,
    EvidenceManagerDialogData,
    FollowUpDialog,
    SubtitleDialog,
    UWRequirementTableActions,
} from './components';
import { InterviewSectionDialog, InterviewSectionDialogData, InterviewSectionDialogResponse } from 'app/interview';
import { Utils } from 'app/utils';
import { formatCaseRequirementName } from './utils/uww-requirement-utils';
import { CaseIntegrationQueueEntry } from '@Models';
import { HubConnection } from '@microsoft/signalr';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'uw-requirements',
    templateUrl: './uw-requirements.component.html',
    styleUrls: ['./uw-requirements.component.scss']
})
export default class UWRequirements implements OnInit, OnDestroy {
    @Input() baseProps: UWBaseProps;
    @Output() onIntegrationRequirementRun = new EventEmitter<UWRequirementTableData>();
    @ViewChild('filePicker') filePicker: ElementRef;

    // Toggles
    reviewDocumentsIsEnabled = false;
    paramedsSendCancelIsEnabled = false;

    // Form
    addCaseRequirementForm: FormGroup;

    showSubtitleField = false;
    caseDocumentAttachments: CaseDocumentsDTO[] = [];
    caseIntegrationQueueEntries: CaseIntegrationQueueEntry[] = [];
    
    requirements: RequirementResponse[] = [];
    requirementsLoaded = false;
    caseRequirements: CaseRequirementResponse[] = [];
    caseRequirementsLoaded = false;
    caseIntegrationQueueEntriesLoaded =  false;

    signalrConnection: HubConnection;
    isSocketManuallyClosed: boolean;

    subs = new SubSink();

    constructor(
        private uwWorkbenchService: UWWorkbenchService,
        public dialog: MatDialog,
        private notificationService: NotificationService,
        private caseDocumentsDataService: CaseDocumentsDataService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private featureManagerService: FeatureManagerService,
        private caseSummaryService: CaseSummaryService,
        private signalrService: SignalRService
    ) {
        this.addCaseRequirementForm = new FormBuilder().group({
            caseRequirement: ['', Validators.required],
            subtitle: ['']
        });
    }

    //#region Helpers

    private updateCaseRequirementStatusByRequirementId(caseRequirementId: string, statusId: string, accountId: string) {
        this.subs.add(this.uwWorkbenchService.updateCaseRequirementStatus(caseRequirementId, statusId, accountId).subscribe());
    }

    private updateCaseRequirementStatus(tableData: UWRequirementTableData, statusId: string) {
        const { account, case: caseDetail, integrationRequirements } = this.baseProps;

        const caseRequirement = this.caseRequirements.find(x => x.id == tableData.caseRequirementId);
        const integration = integrationRequirements.find(x => x.integrationId == tableData.integrationId && x.integrationActionId == tableData.integrationActionId);

        if (caseRequirement) {
            this.updateCaseRequirementStatusByRequirementId(caseRequirement.id, statusId, account.id);
        }
        else if (integration) {
            this.uwWorkbenchService.updateCaseRequirementStatusByIntegration(integration, statusId, account.id, caseDetail.id).subscribe();
        }
        else {
            console.error('Could not update Requirement because there was no case requirement id and it does not have an integration');
        }
    }

    private async updateCaseRequirementThenEmitIntegrationEvent(tableData: UWRequirementTableData, statusId: string) {
        const { account, case: caseDetail, integrationRequirements } = this.baseProps;
        const integration = integrationRequirements.find(x => x.integrationId == tableData.integrationId && x.integrationActionId == tableData.integrationActionId);

        await this.uwWorkbenchService.updateCaseRequirementStatusAsync(integration, statusId, account.id, caseDetail.id).then(() => {
            tableData.status = statusId; // without this, it'll send the status we're changing FROM, not the new status the user chose
            return this.onIntegrationRequirementRun.emit(tableData);
        })
    }

    private viewDocument(accountId: string, caseRequirementId: string) {
        this.subs.add(this.uwWorkbenchService.downloadDocument(accountId, caseRequirementId).subscribe({
            next: (downloadUri) => {
                window.open(downloadUri, '_blank');
            },
            error: _err => {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: "Error loading document" });
            }
        }));
    }

    private viewSection(accountId: string, caseRequirementId: string) {
        this.subs.add(this.uwWorkbenchService.viewSection(accountId, caseRequirementId).subscribe());
    }

    private updateCaseRequirementSubtitle(caseRequirement: CaseRequirement) {
        const subtitleExists = caseRequirement.subtitle && caseRequirement.subtitle != null;
        const title = subtitleExists ? "Edit Subtitle" : "Add Subtitle";

        const dialogRef = this.dialog.open(SubtitleDialog, {
            width: '32rem',
            disableClose: true,
            data: {
                title,
                subtitle: caseRequirement.subtitle
            }
        });

        this.subs.add(dialogRef.afterClosed().subscribe({
            next: value => {
                if (!value)
                    return;

                this.subs.add(this.uwWorkbenchService.updateCaseRequirementSubtitle(caseRequirement.id, value.subtitle, this.baseProps.account.id).subscribe());
            }
        }));
    }

    private updateCaseRequirementFollowUp(tableData: UWRequirementTableData) {
        const { account, case: caseDetail, integrationRequirements } = this.baseProps;

        const caseRequirement = this.caseRequirements.find(x => x.id == tableData.caseRequirementId);
        const integration = integrationRequirements.find(x => x.integrationId == tableData.integrationId && x.integrationActionId == tableData.integrationActionId);

        const title = caseRequirement?.followUp ? "Edit Follow Up Date" : "Add a Follow Up Date";

        const dialogRef = this.dialog.open(FollowUpDialog, {
            width: '32rem',
            disableClose: true,
            data: { title, followUp: caseRequirement?.followUp, clientId: this.baseProps.client.id }
        });

        this.subs.add(dialogRef.afterClosed().subscribe({
            next: value => {
                if (!value) return;

                if (caseRequirement) {
                    this.subs.add(this.uwWorkbenchService.updateCaseRequirementFollowUp(caseRequirement.id, value.followUp, account.id).subscribe());
                }
                else if (integration) {
                    this.subs.add(this.uwWorkbenchService.updateCaseRequirementFollowUpByIntegration(integration, value.followUp, account.id, caseDetail.id).subscribe());
                }
                else {
                    console.error('Could not update Requirement because there was no case requirement id and it does not have an integration');
                }
            }
        }));
    }

    private async openEvidenceViewerDialog(title: string, caseRequirement: CaseRequirement) {
        if (this.reviewDocumentsIsEnabled) {
            const requirement = this.requirements.find(x => x.id == caseRequirement.requirementId);

            this.dialog.open<EvidenceManagerDialog, EvidenceManagerDialogData, EvidenceManagerDialogData>(EvidenceManagerDialog, {
                width: '800px',
                data: {
                    title,
                    client: this.baseProps.client,
                    account: this.baseProps.account,
                    requirement,
                    caseRequirement,
                    caseDocumentAttachments: this.caseDocumentAttachments,
                    caseIntegrationQueueEntries: this.caseIntegrationQueueEntries,
                    readonly: this.baseProps.readonly,
                },
                disableClose: true,
            });
        }
        else {
            this.dialog.open<AttachmentFileManagerDialog, AttachmentFileManagerDialogData, AttachmentFileManagerDialogData>(AttachmentFileManagerDialog, {
                width: '800px',
                data: {
                    title,
                    client: this.baseProps.client,
                    account: this.baseProps.account,
                    caseRequirement,
                    caseDocumentAttachments: this.caseDocumentAttachments,
                    readonly: this.baseProps.readonly,
                },
                disableClose: true,
            });
        }
    }

    private async deleteCaseRequirement(accountId: string, caseRequirement: CaseRequirement) {
        this.subs.add(this.uwWorkbenchService.deleteCaseRequirement(accountId, caseRequirement).subscribe());
    }

    private async openSectionAsRequirement(accountId: string, caseRequirement: CaseRequirement) {
        this.viewSection(accountId, caseRequirement.id);
        this.dialog.open<InterviewSectionDialog, InterviewSectionDialogData, InterviewSectionDialogResponse>(InterviewSectionDialog, {
            width: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: '55vw',
            maxHeight: '90vh',
            data: {
                title: formatCaseRequirementName(caseRequirement.name, caseRequirement.subtitle),
                client: this.baseProps.client,
                account: this.baseProps.account,
                case: this.baseProps.case,
                sectionId: caseRequirement.section.id,
                saveButtonLabel: 'Save'
            },
            disableClose: true,
        });
    }

    //#endregion
    //#region Subscriptions

    subscribeLoadAttachments() {
        this.subs.add(this.caseDocumentsDataService.loadAttachments(this.baseProps.case.id).subscribe({
            next: response => {
                this.caseDocumentAttachments = response;
            }
        }));
    }

    subscribeToRequirements() {
        const { client, account } = this.baseProps;

        this.subs.add(this.uwRequirementsDataService.loadRequirements(client.code, account.id).subscribe({
            next: requirementResponses => {
                this.requirements = requirementResponses;
                this.requirementsLoaded = true;
            }
        }));
    }

    subscribeToCaseRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadCaseRequirements(this.baseProps.client.code, this.baseProps.case.id).subscribe({
            next: caseRequirementResponse => {
                this.caseRequirements = caseRequirementResponse;
                this.caseRequirementsLoaded = true;
            }
        }));
    }

    private subscribeToCaseIntegrationQueue() {
        this.subs.add(this.caseSummaryService.getCaseIntegrationQueueEntries(this.baseProps.case.id, this.baseProps.account.id).subscribe({
            next: caseIntegrationQueueEntries => {
                this.caseIntegrationQueueEntries = caseIntegrationQueueEntries;
                this.baseProps.caseIntegrationQueueEntries = this.caseIntegrationQueueEntries;
                this.caseIntegrationQueueEntriesLoaded = true;
            }
        }));
    }

    subscribeToFeatureToggles() {
        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchFeatureReviewDocumentsClients, this.baseProps.client.id).subscribe({
            next: isEnabled => {
                this.reviewDocumentsIsEnabled = isEnabled;
            }
        }));

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsParamedsSendCancel, this.baseProps.client.id).subscribe({
            next: isEnabled => {
                this.paramedsSendCancelIsEnabled = isEnabled;
            }
        }));
    }

    async setupSignalR() {
        // Create HubConnection

        this.signalrConnection = this.signalrService.getCaseSignalRConnection(this.baseProps.case.id);
        this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes

        // Setup Listeners

        this.signalrConnection.on('medicalSummaryIntegrationNotification', (event) => {
            if (this.baseProps.case.id !== event.caseId) return;

            const caseRequirement = this.caseRequirements.find(x => x.evidence.map(y => y.caseDocumentId).includes(event.documentId));
            if (event.caseDocumentStatusId == CaseDocumentStatus.Summarized) {
                let message = event.documentName + " summarized";
                if (caseRequirement) {
                    message = caseRequirement.name + " document summarized";
                }
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Info,
                    message: message
                });
                this.subs.add(this.uwRequirementsDataService.reloadCaseRequirements(this.baseProps.client.code, this.baseProps.case.id).subscribe({
                    next: caseRequirementResponse => {
                        this.caseRequirements = caseRequirementResponse;
                        this.caseRequirementsLoaded = true;
                    }
                }));
                this.subs.add(this.caseDocumentsDataService.reloadAttachments(this.baseProps.case.id).subscribe({
                    next: response => {
                        this.caseDocumentAttachments = response;
                    }
                }));
            }
            else if (event.caseDocumentStatusId == CaseDocumentStatus.SummaryFailed)
            {
                let message = "Summary failed.";
                if (caseRequirement) {
                    message = caseRequirement.name + " summary failed.";
                }
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Info,
                    message: message
                });
                this.subs.add(this.uwRequirementsDataService.reloadCaseRequirements(this.baseProps.client.code, this.baseProps.case.id).subscribe({
                    next: caseRequirementResponse => {
                        this.caseRequirements = caseRequirementResponse;
                        this.caseRequirementsLoaded = true;
                    }
                }));
            }
        });
        this.signalrConnection.onclose(this.signalrOnClose.bind(this));

        // Start SignalR
        this.signalrConnection.start().then(this.signalrStarted.bind(this)).catch(this.signalrStartError);
    }

    signalrStarted() {
        console.log('UW Requirements | SignalR connection started');
    }

    signalrOnClose() {
        console.log('SignalR Disconnected ');

        if (!this.isSocketManuallyClosed) {
            console.log('SignalR Reconnecting....');
            this.signalrConnection.start();
        }
    }

    signalrStartError(error: any) {
        console.error(error.message);
    }

    destroySignalR() {
        if (this.signalrConnection != null) {
            console.log('SignalR Disconnecting...');
            this.isSocketManuallyClosed = true;
            this.signalrConnection.stop();
        }
    }

    //#endregion
    //#region LifeCycle

    async ngOnInit() {
        this.uwWorkbenchService.setClientInfo(this.baseProps.client);

        this.subscribeLoadAttachments();
        this.subscribeToRequirements();
        this.subscribeToCaseRequirements();
        this.subscribeToCaseIntegrationQueue();
        this.subscribeToFeatureToggles();
        this.setupSignalR();
    }

    async ngOnDestroy() {
        this.subs.unsubscribe();
        this.destroySignalR();
    }

    //#endregion
    //#region Handlers

    toggleSubtitleForm() {
        if (this.addCaseRequirementForm.get('caseRequirement').value) {
            this.showSubtitleField = true;
        }
        else {
            this.showSubtitleField = false;
        }
    }

    handleCaseRequirementAction(event: UWRequirementActionEvent) {
        const { account } = this.baseProps;

        const caseRequirement = this.caseRequirements.find(x => x.id === event.data?.caseRequirementId);

        // Integration Run
        if (event.action === UWRequirementTableActions.RunIntegration)
            return this.onIntegrationRequirementRun.emit(event.data);

        if (
            this.paramedsSendCancelIsEnabled 
            && caseRequirement.integration?.id === IntegrationEnum.ParamedsAps 
            && event.data.status == "00000000-0000-0000-0000-000000000002" /*Ordered*/ 
            && (event.action === UWRequirementTableActions.Waive 
                    || event.action === UWRequirementTableActions.Cancelled)
        )
        {
            let caseRequirementStatus = CaseRequirementStatuses.Cancelled;
            if (event.action === UWRequirementTableActions.Waive)
            {
                caseRequirementStatus = CaseRequirementStatuses.Waived;
            }
            return this.updateCaseRequirementThenEmitIntegrationEvent(event.data, caseRequirementStatus);
        }
        // Status Changes
        if (event.action === UWRequirementTableActions.Completed)
            return this.updateCaseRequirementStatus(event.data, CaseRequirementStatuses.Completed);
        if (event.action === UWRequirementTableActions.OrderSubmitted)
            return this.updateCaseRequirementStatus(event.data, CaseRequirementStatuses.Ordered);
        if (event.action === UWRequirementTableActions.Waive)
            return this.updateCaseRequirementStatus(event.data, CaseRequirementStatuses.Waived);
        if (event.action === UWRequirementTableActions.Pending)
            return this.updateCaseRequirementStatus(event.data, CaseRequirementStatuses.Pending);
        if (event.action === UWRequirementTableActions.Cancelled)
            return this.updateCaseRequirementStatus(event.data, CaseRequirementStatuses.Cancelled);
        // Subtitle
        if (event.action === UWRequirementTableActions.EditSubtitle)
            return this.updateCaseRequirementSubtitle(caseRequirement);
        // Follow Up
        if (event.action === UWRequirementTableActions.FollowUp)
            return this.updateCaseRequirementFollowUp(event.data);
        // Documents
        if (event.action === UWRequirementTableActions.ViewDocument)
            return this.viewDocument(account.id, caseRequirement.id);
        if (event.action === UWRequirementTableActions.AttachDocument || event.action === UWRequirementTableActions.ViewDocumentIcon)
            return this.openEvidenceViewerDialog('Documents', caseRequirement);
        // Delete Case Requirement
        if (event.action === UWRequirementTableActions.Delete)
            return this.deleteCaseRequirement(account.id, caseRequirement);

        // SaaR actions
        if (event.action === UWRequirementTableActions.OpenSection)
            return this.openSectionAsRequirement(account.id, caseRequirement);

        // Add additional case requirement actions actions here
    }

    //#endregion

}
