import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FeatureToggle } from "@Enums";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { RulesDTO } from "@Models/domain/dto";
import { FeatureManagerService } from "@Services";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AppService } from '../../../../app.service';
import { Account } from '../../../../models';

@Component({
    selector: 'general-settings',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './general-settings.component.html',
    styleUrls: ['../account-settings-v2.component.scss', 'general-settings.component.scss'],
    animations: [
        trigger('flyRightToLeft', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('200ms ease-out',
                    style({ height: 70, opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: 70, opacity: 1 }),
                animate('200ms ease-in',
                    style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class GeneralSettingsComponent implements OnInit {
  @Input() accountInfo: Account;
  noteDefaultMessage = 'Application automatically cancelled.  Signature not obtained within the allotted timeframe.';
  labels: any[] = [];

  generalSettingsForm: UntypedFormGroup;
  params: any;
  settings: any;
  useDocGenV2Setting = false;
  useDistributedConfigCacheSetting = false;
  cacheAccountByEntitySetting = false;
  optimizeRuleAndVmkProcessingSetting = false;
  useDocusignV2Setting = false;
  autoCancelByRule = false;
  toggleHtmlEditorFeature = false;
  rules: RulesDTO[] = [];
  htmlEditorConfig: AngularEditorConfig = {
      editable: true,
      minHeight: '10',
      toolbarHiddenButtons: [['customClasses', 'backgroundColor', 'textColor']]
  };
  resetForm: any;
  filteredRules: Observable<any[]>;

  constructor(
    private appService: AppService,
    private _fb: UntypedFormBuilder,
    private featureManagerService: FeatureManagerService,
    private el: ElementRef
  ) {
      this.generalSettingsForm = this._fb.group({
          accountId: '',
          cancelOnHoldCasesInterval: '',
          cancelOnHoldCasesIntervalType: '',
          disableCallMasking: '',
          useCacheForSTP: '',
          agentWarningMessage: '',
          casePurgeDaysBack: ['', [Validators.min(1)]],
          caseExportBeforePurge: '',
          allowCaseAssignment: '',
          saveAllAuditMessagesAsWarnings: '',
          cancelCasesRuleId: '',
          cancelCasesNote: [this.noteDefaultMessage, [Validators.required]],
          useDocGenV2: '',
          useDocGenV2Stealth: '',
          useDistributedConfigCache: '',
          cacheAccountByEntity: '',
          useDocusignV2: '',
          optimizeRuleAndVmkProcessing: ''
      });
  }

  async ngOnInit() {
      this.useDocGenV2Setting = this.featureManagerService.getByName(FeatureToggle.GlobalUseDocGenV2).enabled;
      this.useDistributedConfigCacheSetting = this.featureManagerService.getByName(FeatureToggle.ConfigUseDistributedCache).enabled;
      this.cacheAccountByEntitySetting = this.featureManagerService.getByName(FeatureToggle.ConfigUseDistributedCache).enabled;
      this.optimizeRuleAndVmkProcessingSetting = this.featureManagerService.getByName(FeatureToggle.CaseRuntimeOptimizeRuleAndVmkProcessingSetting).enabled;

      this.useDocusignV2Setting = this.featureManagerService.getByName(FeatureToggle.CaseUseDocusignV2).enabled;
      this.autoCancelByRule = this.featureManagerService.getByName(FeatureToggle.GlobalSettingsAutoCancelByRule).enabled;
      this.toggleHtmlEditorFeature = this.featureManagerService.getByName(FeatureToggle.CaseNotesToggleHtmlEditor).enabled;

      if (this.autoCancelByRule) {
          this.getRules();
      } else {
          this.getSettings();
      }

  }

  getSettings() {
      // GeneralSettings
      this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
          result => {
              if (result.status === 'success') {
                  this.setData(result);
              }
          }
      );
  }

  setData(result: any) {
      this.params = {};
      if (result.data && result.data.length) {

          this.settings = result.data;
          result.data.forEach(item => {
              // bools - default to false
              if (item.name === 'disableCallMasking'
                || item.name === 'useCacheForSTP'
          || item.name === 'allowCaseAssignment'
          || item.name === 'saveAllAuditMessagesAsWarnings'
          || item.name === 'caseExportBeforePurge'
          || item.name === 'useDocGenV2'
          || item.name === 'useDocGenV2Stealth'
          || item.name === 'useDistributedConfigCache'
          || item.name === 'cacheAccountByEntity'
          || item.name === 'optimizeRuleAndVmkProcessing'
          || item.name === 'useDocusignV2') {
                  this.params[item.name] = (item.value === 'true' ? true : false);
              } else {
                  this.params[item.name] = item.value;
              }
          });
      }
      this.params['accountId'] = this.accountInfo.id;
      if (!this.params || this.params['cancelCasesNote'] == null || this.params['cancelCasesNote'] == '') {
          this.params['cancelCasesNote'] = this.noteDefaultMessage;
      }

      this.generalSettingsForm.reset(this.params);
  }

  checkACL(permissionType, redirect?) {
      return this.appService.checkACL('Accounts', permissionType, redirect);
  }

  getRuleOptionText(ruleId) {
      const m = this.rules?.find((rule) => rule.id === ruleId);
      if (m != undefined) {
          return m.name;
      }
  }

  saveGeneralSettings() {
      if (this.generalSettingsForm.controls['cancelCasesRuleId'].value == '') {
          this.generalSettingsForm.controls['cancelCasesNote'].patchValue(this.noteDefaultMessage);
          this.generalSettingsForm.controls['cancelCasesNote'].updateValueAndValidity();
          this.generalSettingsForm.updateValueAndValidity();
      }
      if (this.generalSettingsForm.valid) {
          const formValues = [];

          Object.keys(this.generalSettingsForm.value).forEach(key => {
              if (key !== 'accountId') {
                  const newValue = this.generalSettingsForm.get(key).value;
                  const item = {
                      accountId: this.generalSettingsForm.get('accountId').value,
                      name: key,
                      value: newValue != null ? newValue.toString() : null
                  };

                  if (item.name === 'allowCaseAssignment' && item.value === null) {
                      item.value = false.toString();
                  }

                  if (item.name === 'saveAllAuditMessagesAsWarnings' && item.value === null) {
                    item.value = false.toString();
                  }

                  if (this.settings && this.settings !== null) {
                      const setting = this.settings.find(s => s.name === key);

                      if (setting) {
                          item['id'] = setting.id;
                      }
                  }

                  formValues.push(item);
              }
          }, this);

          this.appService.postData('AccountSettings', formValues)
              .subscribe(
                  data => {
                      if (data.status === 'success') {
                          this.params = [];
                          if (data.data && data.data.length) {
                              const updatedParams = {};
                              this.settings = data.data;
                              data.data.forEach(item => {
                                  if (item.name === 'rxResultsVisibility'
                    || item.name === 'disableCallMasking'
                    || item.name === 'useCacheForSTP'
                    || item.name === 'enableCaseMetrics'
                    || item.name === 'allowCaseAssignment'
                    || item.name === 'saveAllAuditMessagesAsWarnings'
                    || item.name === 'caseExportBeforePurge'
                    || item.name === 'useDocGenV2'
                    || item.name === 'useDocGenV2Stealth'
                    || item.name === 'useDistributedConfigCache') {
                                      updatedParams[item.name] = (item.value === 'true' ? true : false);
                                  } else {
                                      updatedParams[item.name] = item.value;
                                  }
                              });
                              this.params = updatedParams;
                          }
                          this.generalSettingsForm.markAsPristine();
                          this.appService.showMsg('success', 'General Settings changes saved.');
                      } else {
                          this.appService.showMsg('error', data.message);
                      }
                  }
              );
      } else {
          let invalidControl;
          if (this.generalSettingsForm.controls['cancelCasesNote'].invalid && !this.generalSettingsForm.controls['cancelCasesNote'].pristine) {
              invalidControl = this.el.nativeElement.querySelector('[formcontrolname="cancelCasesNote"]');
              this.appService.showMsg('error', 'Case Status Change Note is required');
              invalidControl.focus();
              return;
          }
          if (this.generalSettingsForm.controls['casePurgeDaysBack'].invalid) {
              invalidControl = this.el.nativeElement.querySelector('[formcontrolname="casePurgeDaysBack"]');
              this.appService.showMsg('error', 'Purge Number of days must be greater than 0.');
              invalidControl.focus();
              return;
          }
      }
  }

  isControlEmpty(controlName: string) {
      return !this.generalSettingsForm.contains(controlName) || this.generalSettingsForm.get(controlName).value == null || this.generalSettingsForm.get(controlName).value == '';
  }

  getRules() {
      this.appService.getData(`Rules/${this.accountInfo.id}`).subscribe(
          data => {
              if (data.status == "success") {
                  this.rules = data.data;

                  this.filteredRules = this.generalSettingsForm.get('cancelCasesRuleId').valueChanges.pipe(
                      startWith(''),
                      map(value => this._filter(value || '')),
                  );

                  this.getSettings();
              } else {
                  this.getSettings();
              }
          }
      );
  }

  private _filter(value: string): RulesDTO[] {
      const filterValue = value.toLowerCase();

      return this.rules.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  discardSettings() {
      this.generalSettingsForm.reset(this.params);
      this.generalSettingsForm.markAsPristine();
      this.appService.showMsg('success', 'General Settings changes discarded.');
  }
}
