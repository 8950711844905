import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CaseStatus, FeatureToggle } from '@Enums';
import { CaseDetailsDTO } from '@Models/domain/dto';
import { CaseSummaryService, FeatureManagerService, NotificationService, NotificationSeverity } from '@Services';
import {
    Note,
    UWBaseProps,
    UWDecisionGetRequest,
    UWDecisionNote,
    UWDecisionPatch,
    UWDecisionPatchRequest,
    UWLabel,
    UWOrderIntegrationRequest,
    UWRequirementTableData,
} from '@Underwriting/models';
import { UWDecisionServices, UWWorkbenchService } from '@Underwriting/services';
import { ICaseUserActivity } from 'app/shared/models/ICaseUserActivity';
import CaseSummaryBaseProps from '../models/case-summary-base-props';

@Component({
    selector: 'underwriting-tab',
    templateUrl: './underwriting-tab.component.html',
    styleUrls: ['./underwriting-tab.component.scss']
})
export default class UnderwritingTab implements OnInit {
    @Input() baseProps: CaseSummaryBaseProps;
    @Input() myCaseActivity: ICaseUserActivity;

    @Output() onInterviewUpdated = new EventEmitter<void>();

    uwBaseProps: UWBaseProps;
    showViewDecision: boolean;
    labels: UWLabel[];
    uwDecisionNote: UWDecisionNote;
    isEnabled = false;
    canUpdateDecision = false;
    cachedCase: CaseDetailsDTO;

    notes: Note[] = [];

    constructor(
        protected underwritingService: UWDecisionServices,
        protected featureManagerService: FeatureManagerService,
        protected uwWorkbenchService: UWWorkbenchService,
        protected notificationService: NotificationService,
        protected caseSummaryService: CaseSummaryService,
    ) {
    }

    //#region Loaders

    loadIntegrationRequirements() {
        this.underwritingService.getUnderwritingRequirements(this.uwBaseProps.case.id).subscribe({
            next: integrationRequirements => {
                this.uwBaseProps = {
                    ...this.uwBaseProps,
                    integrationRequirements: integrationRequirements || [],
                    integrationRequirementsLoaded: true
                };
            },
            error: _err => {
                this.uwBaseProps = { ...this.uwBaseProps, integrationRequirementsLoaded: true };
            }
        });
    }

    async loadDecisionNote() {
        const getNoteRequest: UWDecisionGetRequest = {
            "AccountCode": this.uwBaseProps.account.code.replace(this.uwBaseProps.client.code + "-", ""),
            "CaseId": this.uwBaseProps.case.id,
            "ClientCode": this.uwBaseProps.client.code
        };

        this.uwDecisionNote = await this.underwritingService.getDecisionNote(getNoteRequest);
    }

    //#endregion
    //#region Helpers

    IsCaseStatusLocked = () =>
        this.uwBaseProps.case.statusId === CaseStatus.Cancelled
        || this.uwBaseProps.case.statusId === CaseStatus.Done;

    updateReadOnlyStatus(): void {
        if (!this.uwBaseProps) return;

        // DEV NOTE: We might want to hard code this only for a specific client until we implement "Account Settings"
        // so we don't disable stuff for clients who don't expected this to be disabled
        if (this.IsCaseStatusLocked() || !this.myCaseActivity?.isEditor) {
            this.uwBaseProps = { ...this.uwBaseProps, readonly: true };
            return;
        }

        this.uwBaseProps = { ...this.uwBaseProps, readonly: false };

    }

    loadCoreIntegrationRequirements() {
        // Integration requirements need to call updatedInteview before loading the integration requirements
        this.caseSummaryService.updatedInterview.subscribe({ next: () => this.loadIntegrationRequirements() });
    }

    initUwBaseProps() {
        this.uwBaseProps = {
            ...this.baseProps,
            // UW Base Props
            integrationRequirements: [],
            integrationRequirementsLoaded: false,
            readonly: false
        };
    }

    updateCachedCase() {
        this.cachedCase = { ...this.uwBaseProps.case };
    }

    updateUwBaseProps() {
        this.uwBaseProps = {
            ...this.uwBaseProps,
            ...this.baseProps,
        };
        this.updateCachedCase();
    }

    initDecision() {
        this.showViewDecision = true;

        this.featureManagerService.isEnabled(FeatureToggle.CaseUnderwritersUpdateDecisionClient, this.uwBaseProps.client.id).subscribe(isEnabled => {
            this.canUpdateDecision = isEnabled;
        });
    }

    async getLabels() {
        this.labels = await this.underwritingService.getLabels(this.uwBaseProps.account.workflowId);
    }

    //#endregion
    //#region LifeCycle

    async ngOnInit() {
        this.initUwBaseProps();
        this.uwWorkbenchService.setClientInfo(this.uwBaseProps.client);
        this.initDecision();
        this.getLabels();
        this.loadDecisionNote();
        this.loadCoreIntegrationRequirements();
        this.updateReadOnlyStatus();

        this.cachedCase = { ...this.uwBaseProps.case };
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.updateReadOnlyStatus();
        if (changes.baseProps) this.updateUwBaseProps();
        if (changes.case) this.updateCachedCase(); // TECH DEBT: does `case` ever change? Or should we be looking at `baseProps`?
    }

    //#endregion
    //#region Handlers

    handleSectionSaved() {
        this.onInterviewUpdated.emit();
    }

    async handleSaveDecision(decision: UWDecisionPatch) {
        const { client, account, case: caseDetail } = this.uwBaseProps;

        const decisionRequest: UWDecisionPatchRequest = {
            "AccountCode": account.code.replace(client.code + "-", ""),
            "CaseId": caseDetail.id,
            "ClientCode": client.code,
            "UWDecisionPatch": decision
        };

        const patchedDecision = await this.underwritingService.patchDecision(decisionRequest);
        const currentLabel = this.labels.filter(x => x.id == patchedDecision.uwdecisionId);
        this.cachedCase = { ...this.cachedCase, uwLabelName: currentLabel[0].name };
        this.uwDecisionNote = patchedDecision.underWritingNote;

        this.handleHideUpdateDecision();
    }

    handleIntegrationRequirementRun(uwCaseRequirement: UWRequirementTableData) {

        // What happens when you don't have the information needed?
        // | IntegrationId | ActionId | What should system do?
        // --------------------------------------------------------------------------------
        // |  Null         | Null     | What would this mean? (No idea how the system could get into this state)
        // --------------------------------------------------------------------------------
        // |  Null         | ActionId | What would this mean? (In a way the ActionId defines the integration so we could just back fill)
        // --------------------------------------------------------------------------------
        // | IntegrationId | Null     | What would this mean? (This is how it used to work we could run all integrations w/ that id)
        // --------------------------------------------------------------------------------
        // | IntegrationId | ActionId | Go for it? 

        if (!uwCaseRequirement.integrationId && !uwCaseRequirement.integrationActionId) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: 'Call support'
            });
        } else { 
            const runIntegrationRequest: UWOrderIntegrationRequest = {
                integrationId: uwCaseRequirement.integrationId,
                integrationActionId: uwCaseRequirement.integrationActionId,
                status: uwCaseRequirement.status,
            };

            return this.underwritingService.runUnderwritingRequirementV2(this.uwBaseProps.case.id, runIntegrationRequest).subscribe(integrationRequirements => {
                this.uwBaseProps = { ...this.uwBaseProps, integrationRequirements: integrationRequirements || [] };
            });
        }
    }

    handleShowEditDecision() {
        this.showViewDecision = false;
    }
    handleHideUpdateDecision() {
        this.showViewDecision = true;
    }

    //#endregion
}

