import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { IConfigService } from '../config/iconfigservice';
import { Utils } from '@Utils';

@Injectable()
export class BaseService {
    // DEV NOTE: Keeping these private so extension services don't try to direclty manipulate them.
    private _baseApiUrl = '';
    private _basePublicApiUrl = '';
    private _baseUwwApiUrl = '';

    constructor(
        public appService: AppService,
        protected configService: IConfigService,
        protected httpClient: HttpClient
    ) { }

    //#region Helpers

    private createBaseOptions(clientId?: string, options?: object) {
        return {
            ...(options ?? {}),
            headers: this.appService.getHeaders(clientId)
        };
    }

    //#endregion

    get baseApiUrl() {
        if (Utils.isNullOrWhitespace(this._baseApiUrl))
            this._baseApiUrl = this.appService.getAPIBaseURL();

        return this._baseApiUrl;
    }

    get basePublicApiUrl() {
        if (Utils.isNullOrWhitespace(this._basePublicApiUrl))
            this._basePublicApiUrl = this.configService.getConfiguration().publicApiUrl;

        return this._basePublicApiUrl;
    }

    get baseUwwApiUrl() {
        if (Utils.isNullOrWhitespace(this._baseUwwApiUrl))
            this._baseUwwApiUrl = this.configService.getUnderwriterApiUrl();

        return this._baseUwwApiUrl;
    }


    public getData<T>(url: string, clientId?: string, hideWaiting?: boolean, options?: object) {
        if (!hideWaiting || hideWaiting == null) hideWaiting = false;
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.get<T>(url, this.createBaseOptions(clientId, options)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) {
                    this.appService.display(false);
                }
            }));
    }

    public getDataForCache<T = any>(url: string, clientId?: string, hideWaiting?: boolean) {
        return this.httpClient.get<T>(url, this.createBaseOptions(clientId)).pipe(
            shareReplay(1),
            tap(() => {
                //JC - TECH DEBT.  Cleaner way of using the display especially if this is used in a lastValueFrom.  Previous
                // code was not incrementing the spinner if it was pulled from cache but the finalize was decrementing.
                if (!hideWaiting) this.appService.display(true, false, url);
            }),
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) {
                    this.appService.display(false);
                }
            })
        );
    }

    public async getDataAsync<T = any>(url: string, clientId?: string, hideWaiting = false) {
        if (!hideWaiting || hideWaiting == null) hideWaiting = false;
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.get<T>(url, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            }));
    }

    public postData<T>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.post<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                this.appService.display(false);
                if (response) return response;
            }),
            catchError(error => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            })
        );
    }

    public async postDataAsync<T = any>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return await lastValueFrom(this.httpClient.post<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                this.appService.display(false);
                if (response) return response;
            }),
            catchError(error => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            }))
        );
    }

    public putData<T>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.put<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            }));
    }

    public async putDataAsync<T>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return await lastValueFrom(this.httpClient.put<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            }))
        );
    }

    public patchData<T = any>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.patch<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            })
        );
    }
    
    public async patchDataAsync<T = any>(url: string, body?: any, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return await lastValueFrom(this.httpClient.patch<T>(url, body, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            }))
        );
    }

    public deleteData<T = any>(url: string, clientId?: string, hideWaiting = false) {
        if (!hideWaiting) this.appService.display(true, false, url);

        return this.httpClient.delete<T>(url, this.createBaseOptions(clientId)).pipe(
            map(response => {
                if (response) return response;
            }),
            catchError(error => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }),
            finalize(() => {
                if (!hideWaiting) this.appService.display(false);
            })
        );
    }
}
