import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UWRequirementActionEvent, UWRequirementTableData } from '@Underwriting/models';

import { UWRequirementTableActions, UWRequirementTableProps } from '../uw-requirements-expand-table';
import { IntegrationEnum } from '@Enums';


@Component({
    selector: 'uw-requirements-actions-received',
    templateUrl: './uw-requirements-actions-received.component.html',
    styleUrls: ['./uw-requirements-actions-received.component.scss'],
})
export default class UWRequirementsActionsReceivedComponent {
    @Input() caseRequirement: UWRequirementTableData;
    @Input() props: UWRequirementTableProps;
    @Output() onMenuAction = new EventEmitter<UWRequirementActionEvent>();

    public get UWRequirementTableActions() {
        return UWRequirementTableActions;
    }

    handleMenuAction(columnAction: UWRequirementTableActions, caseRequirement: UWRequirementTableData) {
        const actionEvent: UWRequirementActionEvent = {
            action: columnAction,
            data: caseRequirement
        };
        this.handleMenuActionEvent(actionEvent);
    }

    handleMenuActionEvent(event: UWRequirementActionEvent) {
        this.onMenuAction.emit(event);
    }

    // Dev Note: We need to expose the enum to allow the html template to use it in a condition.
    public get IntegrationEnum() {
        return IntegrationEnum;
    }
}
